<template>
  <div style="width:100%;height:100%">
      <div id="viewer"></div>
    <div class="markContainer">
      <Icon type="ios-arrow-dropleft-circle"
            size='36'
            class="ico"
            title="退回"
            @click="back" />
    </div>
    <div class="imgList"
         v-if="categoryList.length>0">
      <div v-for="item in categoryList"
           @click="toNextImg(item)"
           :key="item.id"><img :src="item.panoramaImgPath"
             :title="item.name"
             :alt="item.name">
        <p class="txt">{{item.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Viewer, Animation } from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';
import { PanoramawebDetail } from '@/utils/javaApi.js';
export default {
  name: 'EdtiPanorama',
  props: [],
  data() {
    return {
      imgListFlag: true,
      caption: '',
      panoramaInfo: {},
      categoryList: [], // 要跳转的图片集合
      guidICon: '',
      id: 0,
      imgPath: '',
      viewer: null,
      markersPlugin: null,
      autorotatePlugin: null,
      markers: [],
      markerConfig: {},
      point: {},
      markerInfomodal: false,
      markerTypeMark: 'circle',
      formValidate: {
        tooltip: '',
        panoramaImgId: 0,
        panoramaImgPath: '',
        content: '',
        html: '默认文本',
      },
      tempPoint: {},
      ruleValidate: {
        tooltip: [
          {
            required: true,
            message: 'The tooltip cannot be empty',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.guidICon =
      '<img class="guideIcon" src="http://129.204.9.15:9024/data/wwwroot/joint_flight_java/upload_test/309/panorama/675_50a2982be9c843d68517f4695c5e1f3f.jpg">';
  },
  async mounted() {
    await this.getDetail();
    await this.init();
    await this.intro();
    await this.viewer.once('ready', this.setPoints);
  },
  watch: {},
  methods: {
    async toNextImg(item) {
      this.imgListFlag = false;
      this.markersPlugin.clearMarkers();
      this.markers = [];
      this.viewer.setPanorama(item.panoramaImgPath);
      this.viewer.setOption('caption', item.name);
      await this.getDetail(item.id);
      await this.setPoints();
    },
    async intro() {
      console.log(2);
      new Animation({
        properties: {
          lat: { start: -Math.PI / 2, end: 0 },
          long: { start: Math.PI, end: 0 },
          zoom: { start: 0, end: 50 },
          fisheye: { start: 4, end: 0 },
        },
        duration: 2000,
        easing: 'inOutQuad',
        onTick: (properties) => {
          this.viewer.setOption('fisheye', properties.fisheye);
          this.viewer.rotate({
            longitude: properties.long,
            latitude: properties.lat,
          });
          this.viewer.zoom(properties.zoom);
        },
      });
    },
    back() {
      this.$router.go(-1);
    },
    formatMarke(el) {
      let type = el.markerTypeMark;
      switch (type) {
        case 'text':
          el.style = {
            maxWidth: '320px',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
          };
          //   el.html = el.html2;
          break;
        case 'circle':
          el.svgStyle = {
            fill: 'rgba(255,255,0,0.3)',
            stroke: 'yellow',
            strokeWidth: '2px',
          };
          break;
        case 'guide':
          el.style = {
            maxWidth: '320px',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center',
          };
          el.html = el.html + this.guidICon;
          break;
        default:
          break;
      }
    },
    async getDetail(picId) {
      let res = await this.$post(PanoramawebDetail(), { panorama_id: this.id });
      let picId2 = picId ? picId : this.id;
      if (res.code === 1) {
        let result = res.data.filter((el) => el.id == picId2)[0];
        console.log('结果是', result);
        this.imgPath = result.panoramaImgPath;
        this.caption = result.name;
        this.categoryList = res.data.filter((el) => el.pid != 0);
        result.markers.map((el) => {
          if (el.markerTypeMark == 'circle') {
            delete el.image;
            delete el.html;
          }
          if (el.markerTypeMark == 'guide') {
            delete el.image;
            delete el.circle;
          }
          if (el.markerTypeMark == 'text') {
            delete el.image;
            delete el.circle;
          }
          if (el.markerTypeMark == '2') {
            delete el.circle;
            delete el.html;
          }
        });
        console.log('详情里的markers-----', result.markers);
        this.markers = result.markers;
      } else {
        this.$Message.error(res.msg_customer);
      }
    },
    async setPoints() {
      console.log('setPoints');
      this.markers.forEach((el) => {
        this.formatMarke(el);
        this.markersPlugin.addMarker(el);
      });
    },
    handleMarkerClick() {
      this.markersPlugin.on('select-marker', async (e, marker, data) => {
        if (!data.dblclick) {
          this.point = marker.config;
          console.log('点击的点', this.point);
          if (this.point.markerTypeMark == 'guide') {
            if (this.point.panoramaImgPath) {
              this.viewer.setPanorama(this.point.panoramaImgPath);
              this.viewer.setOption('caption', this.point.name);
              this.markersPlugin.clearMarkers();
              this.markers = [];

              await this.getDetail(this.point.panoramaImgId);
              console.log('将要绘制的点', this.markers);
              await this.setPoints();
            }
          }
        }
      });
    },
    handleViewerClick() {
      let that = this;

      this.viewer.on('click', function (e, data) {
        if (that.markerTypeMark == 'circle') {
        }

        if (that.markerTypeMark == 'text') {
        }

        if (that.markerTypeMark == 'guide') {
          if (this.point.panoramaImgPath) {
            this.viewer.setPanorama(this.point.panoramaImgPath);
          }
        }

        if (that.markerTypeMark == '2') {
        }

        if (!data.rightclick) {
        }
      });
    },
    init() {
      console.log(1);
      this.viewer = new Viewer({
        container: 'viewer',
        panorama: this.imgPath,
        loadingImg:
          'http://129.204.9.15:9024/data/wwwroot/joint_flight_java/upload_test/234/photosphere-logo.gif',
        touchmoveTwoFingers: true,
        mousewheelCtrlKey: true,
        caption: this.caption + '<b>&copy; bigDog</b>',
        // defaultLong: '100deg',
        plugins: [MarkersPlugin],
        defaultLat: -Math.PI / 2,
        defaultLong: Math.PI,
        defaultZoomLvl: 0,
        fisheye: 4,
      });

      this.markersPlugin = this.viewer.getPlugin(MarkersPlugin);

      this.handleViewerClick();
      this.handleMarkerClick();
      //   this.viewer.once('ready', this.randomPoints);
    },
  },
};
</script>


<style lang="scss" scoped>
#viewer {
  width: 100%;
  height: 100%;
  /deep/ .guideIcon {
    width: 32px;
    height: 32px;
    display: block;
    margin: 0 auto;
  }
  /deep/ .psv-panel-content {
    padding: 10px 20px 10px 10px;
    padding-right: 80px;
    line-height: 2em;
  }
}
.markContainer {
  position: fixed;
  right: 0;
  top: 50px;
  z-index: 20;
  width: 60px;
  height: 55px;
  //   background: #333;
  text-align: center;
  .ico {
    color: #fff;
    // border-bottom: 1px solid #000;
    display: block;
    padding: 8px 0;
    cursor: pointer;
    background-color: #ed4014;
  }
}
.imgList {
  position: fixed;
  display: flex;
  bottom: 100px;
  background: rgba(54, 54, 54, 0.7);
  width: 100%;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  z-index: 22;
  div {
    margin: 0 5px;

    // padding: 5px;
    // border: 3px solid #fff;
    img {
      width: 100px;
      height: 100px;
      border: 3px solid #fff;
      cursor: pointer;
    }
    .txt {
      color: #fff;
      text-align: center;
    }
  }
}
</style>
